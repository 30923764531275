<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>
        </v-app-bar>

        <v-card-text class="row content mx-0">
            <v-row align-content="center" justify="center" no-gutters style="margin-top:-150px;">
            
                <v-col cols="12">
                    <div class="display-1 text-center">Unauthorized!</div>

                    <div class="mt-2 subtitle-1 text-center">
                        <p>Your session has expired or your don't have access to this area. Please scan the QR code in the store to start again.</p>
                    </div>
                </v-col>

            </v-row>

            <v-btn absolute bottom left depressed x-large color="error" @click="restart" :icon="$vuetify.breakpoint.smAndDown">
              <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-restart</v-icon> 
              <span v-if="!$vuetify.breakpoint.smAndDown">Restart</span>
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}
</style>